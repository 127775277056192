import 'preact/debug';
import {render} from 'preact';
import {useRef, useState} from 'preact/compat';
import GPXExplorer from './GPXExplorer';

export default function App() {
  const [file, setFile] = useState<File|undefined>(undefined);
  const fileInputRef = useRef<HTMLInputElement|null>(null);

  return (
    <main>
      <div class={'mb-6 mt-6 pl-6'}>
        <p class={'text-xl font-bold'}>Upload GPX File</p>
        <form autoComplete='off' onSubmit={(evt) => {
          evt.preventDefault();
          setFile(fileInputRef.current?.files?.[0]);
        }}>
          <input type={'file'} ref={fileInputRef} accept={'.gpx'} autocomplete='off' />
          <input type={'submit'} value={'Use'} class={'block mt-1 rounded-sm border-primaryDarkened shadow-md bg-primary text-primaryContrast px-6 py-1'} />
        </form>
      </div>

      <GPXExplorer gpxFile={file} />
    </main>
  );
}

render(<App/>, document.body);

