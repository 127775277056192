import type {AdLab, AdLabStage} from './types';
import {XMLParser} from 'fast-xml-parser';

export class labParser {
  private readonly awardMessageRegex = /<p>Award\s*Message:<br \/>(?<awardmsg>.*?)<\/p>$/gim;

  private xmlDocument: any|undefined = undefined;
  private adLabs: Map<string, AdLab> = new Map();

  parse(xmlString: string): Map<string, AdLab> {
    const parser = new XMLParser({
      ignoreAttributes: false,
      attributeNamePrefix: '@',
    });

    this.xmlDocument = parser.parse(xmlString);
    this.xmlDocument['gpx']['wpt'].forEach((wpt: any) => {
      const adLab = this.adLabs.get(wpt.url) ?? {
        name: wpt.urlname.startsWith('S1') ? wpt.urlname.substring(3) : wpt.urlname,
        gcCode: wpt.name,
        url: wpt.url,
        owner: wpt['groundspeak:cache']['groundspeak:placed_by'],
        stages: [],
      };

      // important: reset regex after last execution
      this.awardMessageRegex.lastIndex = 0;

      console.log(wpt);

      const awardMessageRegexExec = this.awardMessageRegex.exec(
        wpt['groundspeak:cache']['groundspeak:long_description']['#text']
          .replaceAll(/[\r\n]+/g, ' ')
          .replaceAll(/\s+/g, ' '),
      );

      console.log(wpt);
      const stage: AdLabStage = {
        coordinates: `${wpt['@lat']}, ${wpt['@lon']}`,
        longDescription: wpt['groundspeak:cache']['groundspeak:long_description']['#text'],
        awardMessage: awardMessageRegexExec?.groups?.awardmsg ?? undefined,
      };

      (adLab.stages as AdLabStage[]).push(stage);
      this.adLabs.set(wpt.url, adLab);
    });

    return this.adLabs;
  }

}
