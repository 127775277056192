import {useEffect, useState} from 'preact/compat';
import type {AdLab} from './labParser/types';
import {labParser} from './labParser/labParser';

export default function GPXExplorer(props: {gpxFile?: File}) {
  const parser = new labParser();
  const [entries, setEntries] = useState<AdLab[]>([]);

  useEffect(() => {
    props.gpxFile?.text()
      .then(textContent => {
        setEntries(Array.from(parser.parse(textContent).values()));
      })
      .catch(err => console.error(err));
  }, [props.gpxFile]);

  if(!props.gpxFile) {
    return null;
  }

  return (
    <ul class={'px-8'}>
      {entries.map((adLab) => (
        <li class={'mt-3'}>
          <p class={'text-2xl text-primary font-bold leading-[1]'}>{adLab.name} <span class={'text-sm font-thin'}>by {adLab.owner}</span></p>
          <a href={adLab.url} target={'_blank'}><p class={'font-light text-sm mt-0 p-0'}>{adLab.url}</p></a>

          <p class={'mt-2 font-bold'}>Award Messages</p>
          <ol class={'list-[stages] list-inside pl-4'}>
            {adLab.stages.map((stage) => (
              <li class={'font-light marker:font-bold'}>
                <span class={'font-thin text-sm'}>{stage.coordinates}</span>
                <p>{stage.awardMessage ?? '(warning: no award message found)'}</p>
              </li>
            ))}
          </ol>
        </li>
      ))}
    </ul>
  );
}
